import * as React from "react"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import useAuthenticationCheck from "../hooks/useAuthenticationCheck"
import { useUser } from "../context/UserContext"
import { useToken } from "../context/UseToken"
import colors from "../styles/colors"
import DataTable from "react-data-table-component"
import Icon from "../components/icon/Icon"
import Modal from "../components/pages/rules/Modal"
import * as XLSX from "xlsx"
import { applyTextStyle } from "../styles/typography"
import SuggestionsList from "../components/lists/SuggestionsList"
import ScoreProgressBar from "../components/ScoreProgressBar"
import PageHeading from "../components/PageHeading"

let cachedRules: any[] = []
let cachedCategories: any[] = []

const MonitorPage: React.FC = () => {
  useAuthenticationCheck()
  const { user: currentActiveUser } = useUser()
  const { getToken } = useToken()

  type State = {
    categories: any[]
    applications: any[]
    selectedAppId: string | null
    messages: any[]
    loading: boolean
  }

  const [state, setState] = useState<State>({
    categories: [],
    applications: [],
    selectedAppId: null,
    messages: [],
    loading: false,
  })

  const columns = [
    {
      name: "id",
      selector: (row: any) => row.id,
      sortable: true,
      grow: 0,
    },
    {
      name: "Timestamp",
      selector: (row: any) => row.createdAt.split(".")[0].replace("T", " "),
      sortable: true,
      grow: 0,
      minWidth: "200px",
    },
    {
      name: "Text",
      selector: (row: any) => row.text,
      sortable: true,
      wrap: true,
    },
    {
      name: "Score",
      selector: (row: any) => row.overallScore,
      sortable: true,
      grow: 0,
    },
  ]

  const [avgOverallScore, setAvgOverallScore] = useState<number | null>(null)
  const [categoryScores, setCategoryScores] = useState<Record<number, number>>(
    {}
  )
  const [problemMessages, setProblemMessages] = useState<any[]>([])
  const [newAppModalOpen, setNewAppModalOpen] = useState<boolean>(false)
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false)
  const [newAppName, setNewAppName] = useState<string>("")

  useEffect(() => {
    // Compute average overallScore
    const totalScore = state.messages.reduce(
      (acc, message) => acc + message.overallScore,
      0
    )
    if (state.messages.length > 0) {
      setAvgOverallScore(
        parseFloat((totalScore / state.messages.length).toFixed(1))
      )
    } else {
      setAvgOverallScore(0)
    }

    // Compute average score for each categoryId
    const categoryScoreSums: Record<number, number> = {}
    const categoryScoreCounts: Record<number, number> = {}

    state.messages.forEach(message => {
      message.scores.forEach((score: any) => {
        if (!categoryScoreSums[score.categoryId]) {
          categoryScoreSums[score.categoryId] = 0
          categoryScoreCounts[score.categoryId] = 0
        }
        categoryScoreSums[score.categoryId] += score.score
        categoryScoreCounts[score.categoryId] += 1
      })
    })

    const avgCategoryScores: Record<number, number> = {}
    for (const categoryId in categoryScoreSums) {
      avgCategoryScores[categoryId] =
        categoryScoreSums[categoryId] / categoryScoreCounts[categoryId]
    }
    setCategoryScores(avgCategoryScores)

    // Select all messages that have an overallScore below 60
    const problemMessages = state.messages.filter(
      message => message.overallScore < 65
    )
    setProblemMessages(problemMessages)
  }, [state.messages])

  const fetchData = async (
    endpoint: string,
    params?: Record<string, string>
  ) => {
    try {
      const token = await getToken()
      const url = new URL(`${process.env.GATSBY_API_URL}/v1/${endpoint}`)
      if (params) {
        Object.keys(params).forEach(key =>
          url.searchParams.append(key, params[key])
        )
      }
      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return await response.json()
    } catch (error) {
      console.error(`Error fetching ${endpoint}`, error)
    }
  }

  const handleApplicationClick = async (id: string) => {
    if (id === "") {
      setState(prevState => ({
        ...prevState,
        selectedAppId: null,
        messages: [],
      }))
      return
    }
    const { application } = await fetchData("application/messages", { id })
    console.log("messages", application.messages)
    console.log(state.applications, id)
    console.log(state.applications.find(app => app.id === parseInt(id))?.name)

    setState(prevState => ({
      ...prevState,
      selectedAppId: id,
      messages: application.messages,
    }))
  }

  useEffect(() => {
    const fetchAndSetData = async () => {
      setState({ ...state, loading: true })

      if (currentActiveUser) {
        const { applications } = await fetchData("application")
        const categories = await fetchData("rules/categories/default")

        setState(prevState => ({
          ...prevState,
          applications,
          categories,
          loading: false,
        }))
      }
    }

    fetchAndSetData()
  }, [currentActiveUser])

  const getScoreColor = (score: number) => {
    const lerp = (start: number, end: number, t: number) =>
      start + t * (end - start)

    const hexToRgb = (hex: string) => {
      const bigint = parseInt(hex.slice(1), 16)
      const r = (bigint >> 16) & 255
      const g = (bigint >> 8) & 255
      const b = bigint & 255

      return { r, g, b }
    }

    const color100 = hexToRgb("#41c5ce")
    const color65 = hexToRgb("#ff9e00")
    const color40 = hexToRgb("#ff0000")

    let r, g, b

    if (score > 90) {
      r = color100.r
      g = color100.g
      b = color100.b
    } else if (score > 65) {
      const t = (score - 65) / 25 // 25 is the range between 65 to 90
      r = lerp(color65.r, color100.r, t)
      g = lerp(color65.g, color100.g, t)
      b = lerp(color65.b, color100.b, t)
    } else if (score >= 40) {
      const t = (score - 40) / 25 // 25 is the range between 40 to 65
      r = lerp(color40.r, color65.r, t)
      g = lerp(color40.g, color65.g, t)
      b = lerp(color40.b, color65.b, t)
    } else {
      r = color40.r
      g = color40.g
      b = color40.b
    }

    return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`
  }

  async function copyApplicationTokenToClipboard() {
    const { token } = await fetchData(
      `application/token?id=${state.selectedAppId}`
    )
    console.log("applicationToken: ", token)
    if (!token) {
      alert(
        "Failed to copy token to clipboard. Please report this issue to Codaco."
      )
      return
    }

    try {
      await navigator.clipboard.writeText(token)
      console.log("Token copied to clipboard!")
      alert("Token copied to clipboard!")
    } catch (err) {
      console.error("Failed to copy token: ", err)
    }
  }

  async function handleCreateApplication() {
    if (!newAppName) {
      alert("Please enter a name for the application")
      return
    }
    console.log("Create application with name: ", newAppName)
    const token = await getToken()
    const response = await fetch(
      `${process.env.GATSBY_API_URL}/v1/application`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: newAppName }),
      }
    )

    if (response.status !== 200) {
      console.error("Failed to create application")
      alert("Failed to create application")
    } else {
      const { applications } = await fetchData("application")
      setState(prevState => ({
        ...prevState,
        applications,
      }))
      // Set active application to the newly created one
      const newApplication = applications.find(
        (app: any) => app.name === newAppName
      )
      if (newApplication) {
        setState(prevState => ({
          ...prevState,
          selectedAppId: newApplication.id,
          messages: [],
        }))
      }

      setNewAppModalOpen(false)
      setNewAppName("")
    }
  }

  async function deleteApplication() {
    // Show alert
    const confirmation = window.confirm(
      "Are you sure you want to delete this application? \nAll messages, associated feedback, and scores will be deleted. \nThis cannot be undone."
    )
    if (!confirmation) return
    const token = await getToken()
    const response = await fetch(
      `${process.env.GATSBY_API_URL}/v1/application?id=${state.selectedAppId}}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    if (response.status !== 200) {
      console.error("Failed to delete application")
      alert("Failed to delete application")
    } else {
      const { applications } = await fetchData("application")
      setState(prevState => ({
        ...prevState,
        applications,
      }))
      setState(prevState => ({
        ...prevState,
        selectedAppId: null,
        messages: [],
      }))
    }
  }
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const uploadExcel = () => {
    fileInputRef.current?.click()
  }
  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const data = await file.arrayBuffer()
    const workbook = XLSX.read(data)

    const firstSheetName = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[firstSheetName]

    const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    let items = json.map((row: any[]) => row[0]) // Creating a list of items from the first column
    if (items.length > 1600) {
      alert("You can upload a maximum of 1600 items at once")
      event.target.value = ""
      return
    }

    items = items.filter((item: string) => item !== undefined && item !== "") // Remove empty items
    console.log("items", items)
    // Show an alert to analyze items
    if (window.confirm(`Do you want to analyze ${items.length} items?`)) {
      const { token } = await fetchData(
        `application/token?id=${state.selectedAppId}`
      )

      try {
        const response = await fetch(
          `${process.env.GATSBY_API_URL}/v1/application/messages`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              messages: items,
              language: "EN",
            }),
          }
        )
        if (response.status !== 200) {
          alert("Failed to upload excel file")
          return
        }
        console.log("response", await response.json())

        if (!state.selectedAppId) return
        const { application } = await fetchData("application/messages", {
          id: state.selectedAppId,
        })
        setState(prevState => ({
          ...prevState,
          messages: application.messages,
        }))
      } catch (err) {
        console.error("Failed send messages to backend: ", err)
      }
      event.target.value = ""
    }
  }

  const downloadExcelTemplate = () => {
    // Your data array
    const data: string[][] = [
      ["Each line is a chatbot response"],
      ["These lines are just examples!"],
      ["Replace them with responses from your chatbot"],
    ]

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.aoa_to_sheet(data)

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template")

    // Write the workbook to a file and trigger download
    XLSX.writeFile(workbook, "excel_template.xlsx")
  }

  // CONSTRUCTING INFORMATION MODAL

  const fetchRule = async (ruleId: string) => {
    if (!ruleId) return
    const cachedRule = cachedRules.find(rule => rule.id === ruleId)
    if (cachedRule) return cachedRule
    console.log("Not cached, fetching")

    const token = await getToken()
    const response = await fetch(
      `${process.env.GATSBY_API_URL}/v1/rules/rule?id=${ruleId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    const rule = await response.json()
    cachedRules.push(rule)
  }

  const fetchCategory = async (categoryId: string) => {
    if (!categoryId) return
    const cachedCategory = cachedCategories.find(
      category => category.id === categoryId
    )
    if (cachedCategory) return cachedCategory
    console.log("Not cached, fetching")

    const token = await getToken()
    const response = await fetch(
      `${process.env.GATSBY_API_URL}/v1/rules/category?id=${categoryId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    const category = await response.json()
    cachedCategories.push(category)
  }

  const [selectedMessageInfo, setSelectedMessageInfo] = useState<any>(null)

  const attachRuleAndCategoryData = (message: any) => {
    // Assuming cachedRules and cachedCategories are available here with all needed data
    const transformedRules = message.rules.map((ruleItem: any) => {
      // Find the corresponding rule data
      const ruleData = cachedRules.find(rule => rule.id === ruleItem.ruleId)
      if (!ruleData) return ruleItem // or handle this case as needed

      // Find the corresponding category data for the rule
      const categoryData = cachedCategories.find(
        category => category.id === ruleData.categoryId
      )
      if (categoryData) {
        ruleData.category = categoryData // Attach category data to the rule
      }

      return {
        ...ruleItem,
        rule: ruleData, // Attach the complete rule data to the rule item
      }
    })

    return {
      ...message,
      rules: transformedRules,
    }
  }

  const enhanceScoresWithCategoryDetails = (message: any) => {
    // Assuming cachedCategories is available here with all needed data

    // Add category details to each score
    const enhancedScores = message.scores.map((scoreItem: any) => {
      const categoryData = cachedCategories.find(
        category => category.id === scoreItem.categoryId
      )
      if (!categoryData) return scoreItem // or handle this case as needed

      return {
        ...scoreItem,
        name: categoryData.name,
        color: categoryData.color,
      }
    })

    // Calculate the overall score
    const overallScore =
      enhancedScores.reduce((acc: any, score: any) => acc + score.score, 0) /
      enhancedScores.length

    return {
      overallScore: Math.round(overallScore),
      scoresArray: enhancedScores,
    }
  }

  const clickMessage = async (message: any) => {
    for (const rule of message.rules) {
      await fetchRule(rule.ruleId)
    }
    const categoryIdsFromMessage: any[] = [
      ...message.rules.map((rule: any) => rule.categoryId),
    ]
    const categoryIdsFromScores: any[] = [
      ...message.scores.map((score: any) => score.categoryId),
    ]
    const categoryIds = [
      ...new Set([...categoryIdsFromMessage, ...categoryIdsFromScores]),
    ]
    for (const categoryId of categoryIds) {
      await fetchCategory(categoryId)
    }

    const transformedMessage = attachRuleAndCategoryData(message)
    const scoresWithDetails =
      enhanceScoresWithCategoryDetails(transformedMessage)

    setSelectedMessageInfo({
      ...transformedMessage,
      scores: scoresWithDetails,
    })

    setInfoModalOpen(true)
  }
  // Utility function to export the content of DataWrapper to PDF
  const exportPDF = async () => {
    const input = document.getElementById("PDFExportWindow")

    if (!input) return

    const canvas = await html2canvas(input)
    const imgData = canvas.toDataURL("image/png")

    const pdf = new jsPDF({
      orientation: "p",
      unit: "px",
      format: [1080, 1920],
    })

    const margin = 20
    pdf.addImage(imgData, "PNG", margin, margin)
    pdf.save("Codaco-Overview.pdf")
  }

  const exportExcel = async () => {
    try {
      // Fetch EXCEL from backend
      const token = await getToken()

      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/application/export?id=${state.selectedAppId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )

      if (!response.ok) {
        throw new Error("Failed to fetch the Excel file")
      }

      // Retrieve the blob response containing the Excel file
      const blob = await response.blob()

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob)

      // Create a temporary anchor element and trigger a download
      const a = document.createElement("a")
      a.href = url
      a.download = "exported_data.xlsx" // This can be dynamically set if needed
      document.body.appendChild(a)
      a.click()

      // Clean up by revoking the Blob URL and removing the anchor element
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      console.error("Error downloading the Excel file:", error)
      alert("Error downloading the file. Please try again.")
    }
  }

  return (
    <>
      <Helmet>
        <title>Codaco Monitor Dashboard</title>
      </Helmet>
      <Wrapper>
        <PageHeading>Codaco Monitor Dashboard</PageHeading>
        <MonitorWrapper>
          <ApplicationButtonsWrapper>
            <label htmlFor="application-select">Choose an application:</label>
            <select
              name="applications"
              id="application-select"
              onChange={e => {
                handleApplicationClick(e.target.value)
              }}
            >
              <option value={""}>None</option>
              {state.applications.length > 0 &&
                state.applications.map(application => (
                  <option key={application.id} value={application.id}>
                    {application.name}
                  </option>
                ))}
            </select>
            {state.selectedAppId && (
              <>
                <button onClick={copyApplicationTokenToClipboard}>
                  {/* <Icon
                  type="ContentCopy"
                  size={16}
                  color={colors.grey2}
                /> */}
                  Copy application key
                </button>
                <button onClick={downloadExcelTemplate}>
                  {/* <Icon type="Folder" size={16} color={colors.grey2} /> */}
                  Download excel template
                </button>
                <button onClick={uploadExcel}>
                  {/* <Icon type="Folder" size={16} color={colors.grey2} /> */}
                  Upload Excel
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />
                <IconButton onClick={exportPDF}>
                  {/* <Icon type="PDF" size={16} /> */}
                  Export to PDF
                </IconButton>
                <IconButton onClick={exportExcel}>
                  {/* <Icon type="PDF" size={16} /> */}
                  Export to Excel
                </IconButton>
                <IconButton onClick={deleteApplication}>
                  <Icon type="Delete" size={16} color={colors.red} />
                  Delete application
                </IconButton>
              </>
            )}
            |
            <button onClick={() => setNewAppModalOpen(true)}>
              + Create New
            </button>
            <Modal
              isOpen={newAppModalOpen}
              onClose={() => setNewAppModalOpen(false)}
            >
              <NewApplicationWrapper>
                <input
                  type="text"
                  id="name"
                  value={newAppName}
                  placeholder="Application name"
                  onChange={e => setNewAppName(e.target.value)}
                />
                <button onClick={handleCreateApplication}>Create</button>
              </NewApplicationWrapper>
            </Modal>
          </ApplicationButtonsWrapper>
          <div id="PDFExportWindow">
            <DataWrapper>
              {state.selectedAppId ? (
                <>
                  <BlockWrapper>
                    <LeftBlock>
                      <Block>
                        <BlockTitle>Number of messages</BlockTitle>
                        <BlockNumber>{state.messages.length}</BlockNumber>
                      </Block>
                      <Block>
                        <BlockTitle>Average score</BlockTitle>
                        <BlockNumber
                          color={getScoreColor(avgOverallScore ?? 100)}
                        >
                          {parseFloat((avgOverallScore ?? 0).toFixed(1))}
                        </BlockNumber>
                      </Block>
                      <Block>
                        <BlockTitle>Red flags</BlockTitle>
                        <BlockNumber>{problemMessages.length}</BlockNumber>
                      </Block>
                    </LeftBlock>
                    <Block>
                      <BlockTitle>Average score per category</BlockTitle>
                      <CategoryScoresWrapper>
                        {state.categories &&
                          state.categories.length > 0 &&
                          state.categories.map(category => (
                            <ListItem key={category.id}>
                              <Pill color={category.color}>
                                {category.name}
                              </Pill>{" "}
                              <Score
                                color={getScoreColor(
                                  categoryScores[category.id]
                                )}
                              >
                                {parseFloat(
                                  (categoryScores[category.id] || 0).toFixed(1)
                                )}
                              </Score>
                            </ListItem>
                          ))}
                      </CategoryScoresWrapper>
                    </Block>
                  </BlockWrapper>
                  <FullBlock>
                    <Block>
                      <BlockTitle>Low scoring messages</BlockTitle>
                      <DataTable
                        columns={columns}
                        data={problemMessages}
                        pagination
                        onRowClicked={row => clickMessage(row)}
                      />
                    </Block>
                  </FullBlock>
                  <FullBlock>
                    <DataTable
                      columns={columns}
                      data={state.messages}
                      pagination
                      onRowClicked={row => clickMessage(row)}
                    />
                    <Modal
                      isOpen={infoModalOpen}
                      onClose={() => setInfoModalOpen(false)}
                    >
                      {selectedMessageInfo &&
                        selectedMessageInfo.rules &&
                        selectedMessageInfo.rules.length && (
                          <>
                            <SuggestionsCounter>
                              <Count>
                                {selectedMessageInfo &&
                                  selectedMessageInfo.rules &&
                                  selectedMessageInfo.rules.length &&
                                  selectedMessageInfo.rules.reduce(
                                    (acc: any, rule: any) =>
                                      acc + rule.words.length,
                                    0
                                  )}
                              </Count>

                              <CountText>Suggestions</CountText>
                            </SuggestionsCounter>

                            <SuggestionListWrapper>
                              <SuggestionsList
                                onMessageClick={true}
                                messages={selectedMessageInfo.rules}
                              />
                            </SuggestionListWrapper>

                            <ScoreContainer>
                              <div>
                                <InfoScore>
                                  {(selectedMessageInfo &&
                                    selectedMessageInfo?.scores
                                      ?.overallScore) ||
                                    0}
                                </InfoScore>
                                <OverallText>Overall score</OverallText>
                              </div>
                              <ScoreRight>
                                {selectedMessageInfo &&
                                  selectedMessageInfo?.scores?.scoresArray?.map(
                                    (scoreItem: any) => (
                                      <ScoreProgressBar
                                        key={scoreItem.name}
                                        title={scoreItem.name}
                                        defaultColor={scoreItem.color} // Use hex string directly
                                        fillColor={scoreItem.color} // Use hex string directly
                                        progress={scoreItem.score}
                                      />
                                    )
                                  )}
                              </ScoreRight>
                            </ScoreContainer>
                          </>
                        )}
                    </Modal>
                  </FullBlock>
                </>
              ) : (
                <p>Select an application to see statistics</p>
              )}
            </DataWrapper>
          </div>
        </MonitorWrapper>
      </Wrapper>
      <div id="modal-root"></div>
    </>
  )
}

export default MonitorPage

const SuggestionListWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

const NewApplicationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;

  button {
    width: min-content;
    padding: 8px;
  }
`

const IconButton = styled.button`
  display: flex;
  align-items: center;
`

const ListItem = styled.div`
  margin-bottom: 15px;
  line-height: 1.8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Pill = styled.span<{ color: String }>`
  color: white;
  font-weight: bold;
  background-color: ${({ color }) => color};
  padding: 3px 8px;
  border-radius: 6px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CategoryScoresWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; // spacing between items
`

const Score = styled.span<{ color: String }>`
  font-weight: bold;
  font-size: 24px;
  color: ${({ color }) => color};
`

const Block = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 0px;
`
const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const FullBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0px;
`
const BlockTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`

const BlockNumber = styled.div<{ color?: String }>`
  font-size: 36px;
  font-weight: bold;
  color: ${({ color }) => color ?? colors.blue1};
`

const BlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`

const DataWrapper = styled.div`
  width: 100%;
`

const MonitorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ApplicationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

const Wrapper = styled.div`
  min-width: 75%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`

const SuggestionsCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 13px;
  margin-top: 20px;
`

const Count = styled.div`
  ${applyTextStyle("label5")};
  width: 24px;
  height: 24px;
  background: ${colors.grey4};
  text-align: center;
  border-radius: 50%;
  color: ${colors.neutralWhite};
  padding: 2.5px 0 2.5px 0;
`

const CountText = styled.div`
  ${applyTextStyle("label1")};
  color: ${colors.grey4};
`

const ScoreContainer = styled.div`
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
`

const InfoScore = styled.div`
  ${applyTextStyle("heading1")};
  color: ${colors.grey6};
`

const OverallText = styled.div`
  ${applyTextStyle("label7")};
  color: ${colors.grey6};
  margin-bottom: 4px;
`

const ScoreRight = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 282px;
  margin-top: 60px;
  gap: 20px;
`
